"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.serializeCollectionsOrderDirectionField = serializeCollectionsOrderDirectionField;
exports.deserializeCollectionsOrderDirectionField = deserializeCollectionsOrderDirectionField;
exports.serializeCollectionsOrderField = serializeCollectionsOrderField;
exports.deserializeCollectionsOrderField = deserializeCollectionsOrderField;
exports.serializeCollections = serializeCollections;
exports.deserializeCollections = deserializeCollections;
const collection_generated_js_1 = require("./collection.generated.js");
const collection_generated_js_2 = require("./collection.generated.js");
const errors_js_1 = require("../box/errors.js");
const json_js_1 = require("../serialization/json.js");
const json_js_2 = require("../serialization/json.js");
const json_js_3 = require("../serialization/json.js");
const json_js_4 = require("../serialization/json.js");
function serializeCollectionsOrderDirectionField(val) {
  return val;
}
function deserializeCollectionsOrderDirectionField(val) {
  if (val == 'ASC') {
    return val;
  }
  if (val == 'DESC') {
    return val;
  }
  throw new errors_js_1.BoxSdkError({
    message: "Can't deserialize CollectionsOrderDirectionField"
  });
}
function serializeCollectionsOrderField(val) {
  return {
    ['by']: val.by,
    ['direction']: val.direction == void 0 ? val.direction : serializeCollectionsOrderDirectionField(val.direction)
  };
}
function deserializeCollectionsOrderField(val) {
  if (!(0, json_js_4.sdIsMap)(val)) {
    throw new errors_js_1.BoxSdkError({
      message: 'Expecting a map for "CollectionsOrderField"'
    });
  }
  if (!(val.by == void 0) && !(0, json_js_2.sdIsString)(val.by)) {
    throw new errors_js_1.BoxSdkError({
      message: 'Expecting string for "by" of type "CollectionsOrderField"'
    });
  }
  const by = val.by == void 0 ? void 0 : val.by;
  const direction = val.direction == void 0 ? void 0 : deserializeCollectionsOrderDirectionField(val.direction);
  return {
    by: by,
    direction: direction
  };
}
function serializeCollections(val) {
  return {
    ['total_count']: val.totalCount,
    ['limit']: val.limit,
    ['offset']: val.offset,
    ['order']: val.order == void 0 ? val.order : val.order.map(function (item) {
      return serializeCollectionsOrderField(item);
    }),
    ['entries']: val.entries == void 0 ? val.entries : val.entries.map(function (item) {
      return (0, collection_generated_js_1.serializeCollection)(item);
    })
  };
}
function deserializeCollections(val) {
  if (!(0, json_js_4.sdIsMap)(val)) {
    throw new errors_js_1.BoxSdkError({
      message: 'Expecting a map for "Collections"'
    });
  }
  if (!(val.total_count == void 0) && !(0, json_js_1.sdIsNumber)(val.total_count)) {
    throw new errors_js_1.BoxSdkError({
      message: 'Expecting number for "total_count" of type "Collections"'
    });
  }
  const totalCount = val.total_count == void 0 ? void 0 : val.total_count;
  if (!(val.limit == void 0) && !(0, json_js_1.sdIsNumber)(val.limit)) {
    throw new errors_js_1.BoxSdkError({
      message: 'Expecting number for "limit" of type "Collections"'
    });
  }
  const limit = val.limit == void 0 ? void 0 : val.limit;
  if (!(val.offset == void 0) && !(0, json_js_1.sdIsNumber)(val.offset)) {
    throw new errors_js_1.BoxSdkError({
      message: 'Expecting number for "offset" of type "Collections"'
    });
  }
  const offset = val.offset == void 0 ? void 0 : val.offset;
  if (!(val.order == void 0) && !(0, json_js_3.sdIsList)(val.order)) {
    throw new errors_js_1.BoxSdkError({
      message: 'Expecting array for "order" of type "Collections"'
    });
  }
  const order = val.order == void 0 ? void 0 : (0, json_js_3.sdIsList)(val.order) ? val.order.map(function (itm) {
    return deserializeCollectionsOrderField(itm);
  }) : [];
  if (!(val.entries == void 0) && !(0, json_js_3.sdIsList)(val.entries)) {
    throw new errors_js_1.BoxSdkError({
      message: 'Expecting array for "entries" of type "Collections"'
    });
  }
  const entries = val.entries == void 0 ? void 0 : (0, json_js_3.sdIsList)(val.entries) ? val.entries.map(function (itm) {
    return (0, collection_generated_js_2.deserializeCollection)(itm);
  }) : [];
  return {
    totalCount: totalCount,
    limit: limit,
    offset: offset,
    order: order,
    entries: entries
  };
}
