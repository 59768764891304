<div id="box-sign">

    <form [formGroup]="boxSignForm" (submit)="submitForm($event)">

        <div class="template">
            <div class="d-flex flex-column">
                <label>Sign Template</label>
                <ejs-dropdownlist #signTemplates class="col-12"
                    [dataSource]="templatesList" 
                    [fields]="{ value: 'id', text: 'templateName' }" 
                    (beforeOpen)="noRecordSpinner($event)" 
                    (change)="emitDropDownSelect($event)">
                    <ng-template #noRecordsTemplate>
                        <div id="no-record" style="padding: 1rem;"></div>
                    </ng-template>
                </ejs-dropdownlist>
            </div>
        </div>

        <div class="selected-template">
            <div class="row">
                <div class="col-3">
                    <label>Email Subject</label>    
                </div>
                <div class="col-9">
                    <ejs-textbox #emailSubject
                        [value]="selectedTemplate().emailSubject"></ejs-textbox>
                </div>
                <div class="col-3">
                    <label>Email Body</label>
                </div>
                <div class="col-9">
                    <ejs-richtexteditor #emailMessage 
                        [toolbarSettings]="richTextEditorTools"
                        [value]="selectedTemplate().emailBody" />
                </div>
            </div>
        </div>

        @if (selectedTemplateInfo().boxSignTags && selectedTemplateInfo().boxSignTags.length > 0) {

            <div class="selected-sign-request" formGroupName="requestData">

                <div class="row">
                
                    @for (tag of selectedTemplateInfo().boxSignTags; track $index) {

                        <div class="col-6">

                            <div class="row">

                                <div class="col-3">
                                    <label>{{tag.tagFormLabel}}</label>
                                </div>
    
                                <div class="col-9">
                                    @if (tag.tagId === 'file_number') {
                                        <ejs-textbox 
                                            [value]="caseNumber"
                                            [enabled]="false" />
                                    } @else if (tag.tagId === 'client_phone_number') {
                                        <ejs-maskedtextbox 
                                            formControlName="{{tag.tagId}}" 
                                            mask='000-000-0000' 
                                            [placeholder]="tag.textValue" />
                                    } @else if (tag.tagId === 'client_dob' || tag.tagId === 'client_dol' || tag.tagId === 'date_of_authorization') {
                                        <ejs-datepicker 
                                            formControlName="{{tag.tagId}}"
                                            [placeholder]="tag.textValue" />
                                    } @else if (tag.tagId === 'provider') {
                                        <ejs-dropdownlist
                                            formControlName="{{tag.tagId}}"
                                            [dataSource]="providersList" 
                                            [placeholder]="tag.textValue" 
                                            [fields]="{ id: 'Id', value: 'Id', text: 'Name' }"
                                            (beforeOpen)="getProviders()" />
                                    } @else if (tag.tagId === 'provider_contact') {
                                        <ejs-dropdownlist
                                            formControlName="{{tag.tagId}}"
                                            [dataSource]="contactsList" 
                                            [placeholder]="tag.textValue" 
                                            [fields]="{ id: 'Id', value: 'Id', text: 'ContactName' }"
                                            (beforeOpen)="getContacts()" />
                                    } @else if (tag.tagId === 'client_state') {
                                        <ejs-dropdownlist
                                            formControlName="{{tag.tagId}}"
                                            [dataSource]="statesList" 
                                            [placeholder]="tag.textValue" 
                                            [fields]="{ id: 'id', value: 'label', text: 'key' }"
                                            (beforeOpen)="getStates()" />
                                    } @else if (tag.tagId === 'case_manager') {
                                        <ejs-dropdownlist
                                            formControlName="{{tag.tagId}}"
                                            [dataSource]="contactsList" 
                                            [placeholder]="tag.textValue" 
                                            [fields]="{ id: 'Id', value: 'Id', text: 'ContactName' }"
                                            (beforeOpen)="getContacts()" />
                                    } @else if (tag.tagId === 'amount_authorized') {
                                        <ejs-numerictextbox
                                            formControlName="{{tag.tagId}}"
                                            [placeholder]="tag.textValue"/>
                                    } @else {
                                        <ejs-textbox 
                                            formControlName="{{tag.tagId}}" 
                                            [placeholder]="tag.textValue" />
                                    }
                                </div>

                            </div>

                        </div>

                    }

                </div>

            </div>

        }

        <div class="form-submit col-12 d-flex justify-content-end">
            <button ejs-button [isPrimary]="false" cssClass="e-outline" (click)="clearForm()">Clear</button>
            <button ejs-button [isPrimary]="true">Submit</button>
        </div>

    </form>

    <div class="case-file-sign-requests">
        <grid-template [settings]="requestsGrid"></grid-template>
    </div>
    
    @if (loadingData()) { <loading-overlay [loading]="loadingData"></loading-overlay> }

</div>