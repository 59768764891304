"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OAuthConfig = exports.BoxOAuth = exports.JwtConfig = exports.BoxJwtAuth = exports.BoxDeveloperTokenAuth = exports.CcgConfig = exports.BoxCcgAuth = void 0;
var ccgAuth_generated_1 = require("./ccgAuth.generated");
Object.defineProperty(exports, "BoxCcgAuth", {
  enumerable: true,
  get: function () {
    return ccgAuth_generated_1.BoxCcgAuth;
  }
});
Object.defineProperty(exports, "CcgConfig", {
  enumerable: true,
  get: function () {
    return ccgAuth_generated_1.CcgConfig;
  }
});
var developerTokenAuth_generated_1 = require("./developerTokenAuth.generated");
Object.defineProperty(exports, "BoxDeveloperTokenAuth", {
  enumerable: true,
  get: function () {
    return developerTokenAuth_generated_1.BoxDeveloperTokenAuth;
  }
});
var jwtAuth_generated_1 = require("./jwtAuth.generated");
Object.defineProperty(exports, "BoxJwtAuth", {
  enumerable: true,
  get: function () {
    return jwtAuth_generated_1.BoxJwtAuth;
  }
});
Object.defineProperty(exports, "JwtConfig", {
  enumerable: true,
  get: function () {
    return jwtAuth_generated_1.JwtConfig;
  }
});
var oauth_generated_1 = require("./oauth.generated");
Object.defineProperty(exports, "BoxOAuth", {
  enumerable: true,
  get: function () {
    return oauth_generated_1.BoxOAuth;
  }
});
Object.defineProperty(exports, "OAuthConfig", {
  enumerable: true,
  get: function () {
    return oauth_generated_1.OAuthConfig;
  }
});
