"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.serializeMetadata = serializeMetadata;
exports.deserializeMetadata = deserializeMetadata;
const metadataBase_generated_js_1 = require("./metadataBase.generated.js");
const metadataBase_generated_js_2 = require("./metadataBase.generated.js");
function serializeMetadata(val) {
  return (0, metadataBase_generated_js_1.serializeMetadataBase)(val);
}
function deserializeMetadata(val) {
  return (0, metadataBase_generated_js_2.deserializeMetadataBase)(val);
}
