// Angular
import { Injectable } from '@angular/core';

// 3rd Party
import { BoxClient, BoxDeveloperTokenAuth, BoxCcgAuth, BoxJwtAuth, BoxOAuth } from 'box-typescript-sdk-gen';
import { GetSignTemplatesQueryParams } from 'box-typescript-sdk-gen/lib/managers/signTemplates.generated';
import { GetSignRequestsQueryParams } from 'box-typescript-sdk-gen/lib/managers/signRequests.generated';

// Internal
import { environment } from '@environments/environment';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { ApiService } from '@services/api/api.service';
import { BoxEndpoints } from '@models/api/Endpoints';

export interface BoxInitialization {
  auth: BoxDeveloperTokenAuth | BoxCcgAuth | BoxJwtAuth | BoxOAuth,
  client: BoxClient
}

@Injectable({
  providedIn: 'root'
})
export class BoxService {

  constructor(
    private toast: ToastMessageService,
    private api: ApiService
  ) {}

  box: BoxInitialization;
  boxSignFolderId: string = '207881971831';

  private initializeBox(token: string): BoxInitialization {
    let auth = new BoxDeveloperTokenAuth({ token });
    let client = new BoxClient({ auth });

    return { auth: auth, client: client };
  }

  async getTemplates(token: string = environment.BoxDeveloperToken, limit?: number) {
    const box = this.initializeBox(token);
    return (await box.client.signTemplates.getSignTemplates({ limit: limit } satisfies GetSignTemplatesQueryParams))?.entries;
  }

  async fetchTemplates() {
    return this.api.fetchRequest(BoxEndpoints.Templates);
  }

  async getFolders(token: string = environment.BoxDeveloperToken) {
    const box = this.initializeBox(token);
    return (await box.client.folders.getFolderItems('0')).entries;
  }

  async getAllSignRequests(token = environment.BoxDeveloperToken, limit?: number) {
    const box = this.initializeBox(token);
    return (await box.client.signRequests.getSignRequests({ limit: limit } satisfies GetSignRequestsQueryParams))?.entries;
  }

  async getFileSignRequestById(token = environment.BoxDeveloperToken, fileId?: string) {
    const box = this.initializeBox(token);
    return (await box.client.signRequests.getSignRequestById(fileId ?? '301f78c8-46c8-4578-b7d4-d264b8950d59'));
  }

  async postNewSignRequest(data: any) {
    const response = await this.api.fetchRequest(BoxEndpoints.SignRequests, 'POST', data);

    if (response.ok) {
      this.toast.showSuccess('Box Sign Request created successfully. Reqest will be forwarded to email.')
    } else {
      console.error(response);
      this.toast.showError('Error submitting Box Sign Request. Please review logs for more info.');
    }

    return response;
  }
}