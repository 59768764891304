import { flattenedDecrypt } from '../flattened/decrypt.js';
import { JWEDecryptionFailed, JWEInvalid } from '../../util/errors.js';
import isObject from '../../lib/is_object.js';
export async function generalDecrypt(jwe, key, options) {
  if (!isObject(jwe)) {
    throw new JWEInvalid('General JWE must be an object');
  }
  if (!Array.isArray(jwe.recipients) || !jwe.recipients.every(isObject)) {
    throw new JWEInvalid('JWE Recipients missing or incorrect type');
  }
  if (!jwe.recipients.length) {
    throw new JWEInvalid('JWE Recipients has no members');
  }
  for (const recipient of jwe.recipients) {
    try {
      return await flattenedDecrypt({
        aad: jwe.aad,
        ciphertext: jwe.ciphertext,
        encrypted_key: recipient.encrypted_key,
        header: recipient.header,
        iv: jwe.iv,
        protected: jwe.protected,
        tag: jwe.tag,
        unprotected: jwe.unprotected
      }, key, options);
    } catch {}
  }
  throw new JWEDecryptionFailed();
}