import { flattenedVerify } from '../flattened/verify.js';
import { JWSInvalid, JWSSignatureVerificationFailed } from '../../util/errors.js';
import isObject from '../../lib/is_object.js';
export async function generalVerify(jws, key, options) {
  if (!isObject(jws)) {
    throw new JWSInvalid('General JWS must be an object');
  }
  if (!Array.isArray(jws.signatures) || !jws.signatures.every(isObject)) {
    throw new JWSInvalid('JWS Signatures missing or incorrect type');
  }
  for (const signature of jws.signatures) {
    try {
      return await flattenedVerify({
        header: signature.header,
        payload: jws.payload,
        protected: signature.protected,
        signature: signature.signature
      }, key, options);
    } catch {}
  }
  throw new JWSSignatureVerificationFailed();
}