"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.serializeShieldInformationBarrierSegmentTypeField = serializeShieldInformationBarrierSegmentTypeField;
exports.deserializeShieldInformationBarrierSegmentTypeField = deserializeShieldInformationBarrierSegmentTypeField;
exports.serializeShieldInformationBarrierSegment = serializeShieldInformationBarrierSegment;
exports.deserializeShieldInformationBarrierSegment = deserializeShieldInformationBarrierSegment;
const shieldInformationBarrierBase_generated_js_1 = require("./shieldInformationBarrierBase.generated.js");
const shieldInformationBarrierBase_generated_js_2 = require("./shieldInformationBarrierBase.generated.js");
const userBase_generated_js_1 = require("./userBase.generated.js");
const userBase_generated_js_2 = require("./userBase.generated.js");
const utils_js_1 = require("../internal/utils.js");
const utils_js_2 = require("../internal/utils.js");
const errors_js_1 = require("../box/errors.js");
const json_js_1 = require("../serialization/json.js");
const json_js_2 = require("../serialization/json.js");
function serializeShieldInformationBarrierSegmentTypeField(val) {
  return val;
}
function deserializeShieldInformationBarrierSegmentTypeField(val) {
  if (val == 'shield_information_barrier_segment') {
    return val;
  }
  throw new errors_js_1.BoxSdkError({
    message: "Can't deserialize ShieldInformationBarrierSegmentTypeField"
  });
}
function serializeShieldInformationBarrierSegment(val) {
  return {
    ['id']: val.id,
    ['type']: val.type == void 0 ? val.type : serializeShieldInformationBarrierSegmentTypeField(val.type),
    ['shield_information_barrier']: val.shieldInformationBarrier == void 0 ? val.shieldInformationBarrier : (0, shieldInformationBarrierBase_generated_js_1.serializeShieldInformationBarrierBase)(val.shieldInformationBarrier),
    ['name']: val.name,
    ['description']: val.description,
    ['created_at']: val.createdAt == void 0 ? val.createdAt : (0, utils_js_1.serializeDateTime)(val.createdAt),
    ['created_by']: val.createdBy == void 0 ? val.createdBy : (0, userBase_generated_js_1.serializeUserBase)(val.createdBy),
    ['updated_at']: val.updatedAt == void 0 ? val.updatedAt : (0, utils_js_1.serializeDateTime)(val.updatedAt),
    ['updated_by']: val.updatedBy == void 0 ? val.updatedBy : (0, userBase_generated_js_1.serializeUserBase)(val.updatedBy)
  };
}
function deserializeShieldInformationBarrierSegment(val) {
  if (!(0, json_js_2.sdIsMap)(val)) {
    throw new errors_js_1.BoxSdkError({
      message: 'Expecting a map for "ShieldInformationBarrierSegment"'
    });
  }
  if (!(val.id == void 0) && !(0, json_js_1.sdIsString)(val.id)) {
    throw new errors_js_1.BoxSdkError({
      message: 'Expecting string for "id" of type "ShieldInformationBarrierSegment"'
    });
  }
  const id = val.id == void 0 ? void 0 : val.id;
  const type = val.type == void 0 ? void 0 : deserializeShieldInformationBarrierSegmentTypeField(val.type);
  const shieldInformationBarrier = val.shield_information_barrier == void 0 ? void 0 : (0, shieldInformationBarrierBase_generated_js_2.deserializeShieldInformationBarrierBase)(val.shield_information_barrier);
  if (!(val.name == void 0) && !(0, json_js_1.sdIsString)(val.name)) {
    throw new errors_js_1.BoxSdkError({
      message: 'Expecting string for "name" of type "ShieldInformationBarrierSegment"'
    });
  }
  const name = val.name == void 0 ? void 0 : val.name;
  if (!(val.description == void 0) && !(0, json_js_1.sdIsString)(val.description)) {
    throw new errors_js_1.BoxSdkError({
      message: 'Expecting string for "description" of type "ShieldInformationBarrierSegment"'
    });
  }
  const description = val.description == void 0 ? void 0 : val.description;
  if (!(val.created_at == void 0) && !(0, json_js_1.sdIsString)(val.created_at)) {
    throw new errors_js_1.BoxSdkError({
      message: 'Expecting string for "created_at" of type "ShieldInformationBarrierSegment"'
    });
  }
  const createdAt = val.created_at == void 0 ? void 0 : (0, utils_js_2.deserializeDateTime)(val.created_at);
  const createdBy = val.created_by == void 0 ? void 0 : (0, userBase_generated_js_2.deserializeUserBase)(val.created_by);
  if (!(val.updated_at == void 0) && !(0, json_js_1.sdIsString)(val.updated_at)) {
    throw new errors_js_1.BoxSdkError({
      message: 'Expecting string for "updated_at" of type "ShieldInformationBarrierSegment"'
    });
  }
  const updatedAt = val.updated_at == void 0 ? void 0 : (0, utils_js_2.deserializeDateTime)(val.updated_at);
  const updatedBy = val.updated_by == void 0 ? void 0 : (0, userBase_generated_js_2.deserializeUserBase)(val.updated_by);
  return {
    id: id,
    type: type,
    shieldInformationBarrier: shieldInformationBarrier,
    name: name,
    description: description,
    createdAt: createdAt,
    createdBy: createdBy,
    updatedAt: updatedAt,
    updatedBy: updatedBy
  };
}
