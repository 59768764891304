"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecentItemsManager = exports.GetRecentItemsHeaders = void 0;
const recentItems_generated_js_1 = require("../schemas/recentItems.generated.js");
const network_generated_js_1 = require("../networking/network.generated.js");
const utils_js_1 = require("../internal/utils.js");
const utils_js_2 = require("../internal/utils.js");
const fetchOptions_generated_js_1 = require("../networking/fetchOptions.generated.js");
const fetch_js_1 = require("../networking/fetch.js");
class GetRecentItemsHeaders {
  constructor(fields) {
    /**
     * Extra headers that will be included in the HTTP request. */
    this.extraHeaders = {};
    if (fields.extraHeaders !== undefined) {
      this.extraHeaders = fields.extraHeaders;
    }
  }
}
exports.GetRecentItemsHeaders = GetRecentItemsHeaders;
class RecentItemsManager {
  constructor(fields) {
    this.networkSession = new network_generated_js_1.NetworkSession({});
    if (fields.auth !== undefined) {
      this.auth = fields.auth;
    }
    if (fields.networkSession !== undefined) {
      this.networkSession = fields.networkSession;
    }
  }
  /**
   * Returns information about the recent items accessed
   * by a user, either in the last 90 days or up to the last
   * 1000 items accessed.
   * @param {GetRecentItemsQueryParams} queryParams Query parameters of getRecentItems method
   * @param {GetRecentItemsHeadersInput} headersInput Headers of getRecentItems method
   * @param {CancellationToken} cancellationToken Token used for request cancellation.
   * @returns {Promise<RecentItems>}
   */
  getRecentItems() {
    return __awaiter(this, arguments, void 0, function* (queryParams = {}, headersInput = new GetRecentItemsHeaders({}), cancellationToken) {
      const headers = new GetRecentItemsHeaders({
        extraHeaders: headersInput.extraHeaders
      });
      const queryParamsMap = (0, utils_js_1.prepareParams)({
        ['fields']: queryParams.fields ? queryParams.fields.map(utils_js_2.toString).join(',') : undefined,
        ['limit']: (0, utils_js_2.toString)(queryParams.limit),
        ['marker']: (0, utils_js_2.toString)(queryParams.marker)
      });
      const headersMap = (0, utils_js_1.prepareParams)(Object.assign({}, headers.extraHeaders));
      const response = yield (0, fetch_js_1.fetch)(new fetchOptions_generated_js_1.FetchOptions({
        url: ''.concat(this.networkSession.baseUrls.baseUrl, '/2.0/recent_items'),
        method: 'GET',
        params: queryParamsMap,
        headers: headersMap,
        responseFormat: 'json',
        auth: this.auth,
        networkSession: this.networkSession,
        cancellationToken: cancellationToken
      }));
      return Object.assign(Object.assign({}, (0, recentItems_generated_js_1.deserializeRecentItems)(response.data)), {
        rawData: response.data
      });
    });
  }
}
exports.RecentItemsManager = RecentItemsManager;
